$lib.ready(() => {
    'use strict';
    // Time to wait before moving to next slide
    const DELAY_MS = 4500;
    // Time to wait after user clicked navigational element
    const DELAY_AFTER_INTERACTION = 2 * DELAY_MS;
    let inputs = document.querySelectorAll(".carousel-activator");
    let interval;
    let initInterval = function() {
        window.clearInterval(interval);
        interval = window.setInterval(function() {
            let nextIndex = ([...inputs].findIndex(el => el.checked) + 1) % inputs.length;
            inputs[nextIndex].checked = true;
        }, DELAY_MS);
    }
    initInterval();
    document.querySelectorAll(".carousel label").forEach(el => {
        el.addEventListener('click', () => {
            window.clearInterval(interval);
            window.setTimeout(initInterval, DELAY_AFTER_INTERACTION - DELAY_MS);
        })
    });
});
